;(function($){
//------------------------------------------------------------------------------------------------------------------------
    // (1) Inicializacio

    // Document ready
    $(function(){
        // Kereses kinyitasa
        $('header .navbar').on('click', '.search-toggle', function(e){
            $('body').toggleClass('search-open');
        });

        // Select2
        $('select.form-control').each(function(i, target){
            Page.setSelect2($(target));
        });

        // DateRangePicker
        $('.form-control.form-daterangepicker').each(function(i, target){
            Page.setDateRangePicker($(this));
        });

        // File input
        $('.form-control[type="file"]').each(function(i, target){
            Page.setFileInput($(this));
        });

        // Termek galeria
        $('.product-gallery').each(function(i, target){
            Page.setProductGallery($(this));
        });

        // Fooldali hircarousel
        $('.article-carousel').each(function(i, target){
            Page.setArticleCarousel($(this));
        });

        // Fooldali socialcarousel
        $('.social-carousel').each(function(i, target){
            Page.setSocialCarousel($(this));
        });

        // Fooldali esemenycarousel
        $('#carousel-forthcoming-events').each(function(i, target){
            Page.setEventCarousel($(this));
        });

        // 3 oszlopos carousel
        $('.carousel-3column .item').each(function(){
            var $next = $(this).next();
            var $carousel = $(this).closest('.carousel');

            // Ha nincs kovetkezo, de vegtelenitett a carousel, akkor az elso elem lesz az
            if(!$next.length){
                if($carousel.data('wrap') !== false) $next = $(this).siblings(':first');
                else return true;
            }

            // Kovetkezo bemasolasa masodiknak
            $next.children(':first-child').clone().addClass('hidden-xs').appendTo($(this));

            // Kovetkezo utani bemasolasa harmadiknak
            if($next.next().length){
                $next.next().children(':first-child').clone().addClass('visible-md visible-lg').appendTo($(this));
            } else {
                if($carousel.data('wrap') !== false) $(this).siblings(':first').children(':first-child').clone().addClass('visible-md visible-lg').appendTo($(this));
                else return true;
            }
        });

        // Tagadatlap kinyitasa
        $('.members').on('click', '.member .thumbnail', function(e){
            e.preventDefault();
            var $target = $(this).closest('.member');
            var active = $target.hasClass('active');
            $('.members .member').removeClass('active');
            if(!active) $target.addClass('active');
        });

        // Tagadatlap bezarasa
        $('.members').on('click', '.close', function(e){
            e.preventDefault();
            $('.members .member').removeClass('active');
        });

        // Sportagaink accordion
        $('.filters').on('show.bs.collapse', '.collapse', function(e){
            var $toggle = $('[data-target="#' + this.id + '"]:first');
            if($toggle.length && $toggle.data('parent')){
                $($toggle.data('parent')).find('.collapse.in').collapse('hide');
            }
        });

        // Kosar: Inicializalas
        Shop.init();

        // Layout atmeretezese
        Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // (2) Oldal betoltese

    // Oldal betoltese
    $(window).on('load', function(e){
        // Layout atmeretezese
        Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // (3) Oldal atmeretezese vagy nezet valtasa

    // Oldal atmeretezese vagy nezet valtasa
    $(window).on('orientationchange resize', function(e){
        // Layout atmeretezese
        Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
})(jQuery);
