//------------------------------------------------------------------------------------------------------------------------
    // (1) Oldal konstruktor

// Oldal konstruktor
var Page = (function($){
    // Alapertelmezett datumformatumok
    this.localeDate = 'YYYY.MM.DD.';
    this.localeTime = 'hh:mm';

    return {

//------------------------------------------------------------------------------------------------------------------------
    // (2) Globalis valtozok

        // Reszponziv meretek
        mobileMaxWidth: 767,
        tabletMaxWidth: 991,

        // select2
        select2: {
            containerCssClass: ':all:',
        },

        // Datumformatumok
        localeDate: this.localeDate,
        localeTime: this.localeTime,
        localeTimeAmCharts: this.localeTimeAmCharts,
        localeDateTime: this.localeDateTime + ' ' + this.localeTime,
        localeDateTimeAmCharts: this.localeDateTime + ' ' + this.localeTimeAmCharts,

        // select2
        select2: {
            containerCssClass: ':all:'
        },

        // dateRangePicker
        dateRangePicker: {
            alignments: ['left', 'center', 'right'],
            autoUpdateInput: false,
            locale: {
                format: this.localeDate,
                applyLabel: 'Kiválaszt',
                cancelLabel: 'Törlés',
            },
            opens: 'center',
        },

        // productGallery
        productGallery: {
            currentPagerPosition: 'left',
            enableDrag: true,
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 15,
            thumbItem: 4,
            onSliderLoad: function(e){
                e.lightGallery({
                    download: false,
                    selector: '.lslide',
                });
            },
        },

        // articleCarousel
        articleCarousel: {
            controls: false,
            item: 3,
            loop: false,
            pager: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        item: 2,
                        slideMove: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        item: 1,
                        slideMove: 1,
                    },
                },
            ],
            slideMargin: 0,
            slideMove: 3,
        },

        // socialCarousel
        socialCarousel: {
            auto: true,
            controls: false,
            item: 1,
            loop: true,
            pager: true,
            pause: 6000,
            slideMargin: 0,
            slideMove: 1,
        },

//------------------------------------------------------------------------------------------------------------------------
    // (3) Layout atmeretezese

        // Layout atmeretezese
        resizeLayout: function(){
        },

//------------------------------------------------------------------------------------------------------------------------
    // (4) Select2

        // Select2 betoltese
        setSelect2: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.select2, params);

            // Kereses kikapcsolasa
            if(!$target.data('search')) settings.minimumResultsForSearch = -1;

            // Megjelenites
            $target.select2(settings);
        },

//------------------------------------------------------------------------------------------------------------------------
    // (5) DateRangePicker

        // DateRangePicker betoltese
        setDateRangePicker: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.dateRangePicker, params);
            if($target.data('opens') && settings.alignments.indexOf($target.data('opens')) > -1){
                settings.opens = $target.data('opens');
            }

            // Megjelenites
            $target.daterangepicker(settings);

            // Esemenyek
            $target.on('apply.daterangepicker', function(e, picker){
                $(this).val(picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format));

                // Kapcsolodo meghivas
                if($target.data('target') && $('#' + $target.data('target')).length){
                    getEvents(picker.startDate.format(picker.locale.format), picker.endDate.format(picker.locale.format))
                }
            });
            $target.on('cancel.daterangepicker', function(e, picker){
                $(this).val('');

                // Kapcsolodo meghivas
                if($target.data('target') && $('#' + $target.data('target')).length){
                    //
                }
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // (6) File input

        // File input betoltese
        setFileInput: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var $label = $target.next('label');
            $target.data('defaultLabel', $label.html());

            // Fajl kivalasztasa
            $target.on('change', function(e){
                var fileName = e.target.value ? e.target.value.split('\\').pop() : '';
                $label.find('span.filename').html(fileName ? fileName : $target.data('defaultLabel'));
            });

            // Firefox bug fix
            $target.on('focus', function(e){
                $target.addClass('has-focus');
            });
            $target.on('blur', function(e){
                $target.removeClass('has-focus');
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // (7) Termek galeria

        // Termek galeria betoltese
        setProductGallery: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.productGallery, params);

            // Megjelenites
            $target.lightSlider(settings);
        },

//------------------------------------------------------------------------------------------------------------------------
    // (8) Fooldali hircarousel

        // Fooldali hircarousel betoltese
        setArticleCarousel: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.articleCarousel, params);

            // Megjelenites
            var slider = $target.lightSlider(settings);
            var $container = $target.closest('.article-carousel-container');

            // Elozo lap
            $container.on('click', '.left.carousel-control', function(e){
                e.preventDefault();
                slider.goToPrevSlide();
            });

            // Kovetkezo lap
            $container.on('click', '.right.carousel-control', function(e){
                e.preventDefault();
                slider.goToNextSlide();
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // (9) Fooldali esemenycarousel

        // Fooldali esemenycarousel betoltese
        setEventCarousel: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            $.fn.carousel.Constructor.TRANSITION_DURATION = 1000;

            // Megjelenites
            $target.carousel();
        },

//------------------------------------------------------------------------------------------------------------------------
    // (10) Fooldali socialcarousel

        // Fooldali socialcarousel betoltese
        setSocialCarousel: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.socialCarousel, params);

            // Megjelenites
            var slider = $target.lightSlider(settings);
        },

//------------------------------------------------------------------------------------------------------------------------
    // (11) Hibauzenet megjelenitese

        // Hibauzenet megjelenitese
        showModal: function(id, message, title){
            // Beallitasok felulbiralasa
            title = typeof title !== 'undefined' ? title : 'Hiba történt!';

            // Letezik-e a modal?
            if(!id) return false;
            var $modal = $('#modal-' + id);
            if(!$modal.length) return false;

            // Megjelenites
            $('#modal-title', $modal).html(title);
            $('.modal-body', $modal).html($('<p>' + message + '</p>'));
            $modal.modal();
        },

//------------------------------------------------------------------------------------------------------------------------
    };
}(jQuery));
