//------------------------------------------------------------------------------------------------------------------------
    // (1) Shop konstruktor

// Shop konstruktor
var Shop = (function($){
    return {

//------------------------------------------------------------------------------------------------------------------------
    // (2) Globalis valtozok

        cartPopup: $('#cart-popup'),
        cart: $('#cart-table'),

//------------------------------------------------------------------------------------------------------------------------
    // (3) Kosar: Inicializalas

        // Kosar: Inicializalas
        init: function(){
            // Popup frissitese
            if(Shop.cartPopup.length) Shop.list();
            // Ha se kosar, se popup, akkor kilepunk
            else if(!Shop.cart.length) return false;

            // Esemenyek
            Shop.eventBind($('body'));
        },

        // Kosar: Inicializalas
        eventBind: function($target){
            $target.find('[data-product-id]').each(function(){

                // Gomb: Hozzaadas
                $(this).on('click', 'a[data-method="shop-add"], button[data-method="shop-add"]', function(e){
                    e.preventDefault();

                    // Termek
                    var $product = $(this).closest('[data-product-id]');
                    var params = {
                        termek_id: $product.data('product-id'),
                        meret_id: $product.find('select[name="size"]:first').val(),
                        darabszam: $product.find('input[name="amount"]:first').val(),
                    };

                    // Ha tipusa van
                    if($product.find('select#tipusvalaszto').length){
                        params.termek_id = $product.find('select#tipusvalaszto:first').val();
                        params.meret_id = $product.find('select#tipusvalaszto:first').data('size-id');
                    }

                    // Hozzaadas
                    Shop.add(params);
                });

                // Gomb: Torles
                $(this).on('click', 'a[data-method="shop-remove"], button[data-method="shop-remove"]', function(e){
                    e.preventDefault();

                    // Termek
                    var $product = $(this).closest('[data-product-id]');
                    var params = {
                        termek_id: $product.data('product-id'),
                    };

                    // Torles
                    Shop.remove(params);
                });

                // Mennyiseg: Modositas
                $(this).on('keypress', 'input[type="text"][data-method="shop-change"]', function(e){
                    if(e.which == 13){
                        e.preventDefault();

                        // Termek
                        var $product = $(this).closest('[data-product-id]');
                        var $size = $(this).closest('[data-size-id]');
                        var $amount = $(this);
                        var value = parseInt($amount.val());
                        debug.log(value);
                        if(!value || value < 1) value = 1;

                        var params = {
                            termek_id: $product.data('product-id'),
                            meret_id: $size.data('size-id'),
                            darabszam: value
                        };

                        // Modositas
                        Shop.change(params);
                    }
                });

                // Mennyiseg: Noveles
                $(this).on('click', '.btn[data-method="shop-inc"]', function(e){
                    e.preventDefault();

                    // Termek
                    var $product = $(this).closest('[data-product-id]');
                    var $size = $(this).closest('[data-size-id]');
                    var $amount = $size.find('input[type="text"][data-method="shop-change"]');
                    var value = parseInt($amount.val()) + 1;
                    if(!value || value < 1) value = 1;

                    var params = {
                        termek_id: $product.data('product-id'),
                        meret_id: $size.data('size-id'),
                        darabszam: value
                    };

                    // Modositas
                    Shop.change(params);
                });

                // Mennyiseg: Csokkentes
                $(this).on('click', '.btn[data-method="shop-dec"]', function(e){
                    e.preventDefault();

                    // Termek
                    var $product = $(this).closest('[data-product-id]');
                    var $size = $(this).closest('[data-size-id]');
                    var $amount = $size.find('input[type="text"][data-method="shop-change"]');
                    var value = parseInt($amount.val()) - 1;
                    if(!value || value < 1) value = 1;

                    var params = {
                        termek_id: $product.data('product-id'),
                        meret_id: $size.data('size-id'),
                        darabszam: value
                    };

                    // Modositas
                    Shop.change(params);
                });

                // Gomb: Opciok torlese
                $(this).on('click', 'a[data-method="shop-clear-input"]', function(e){
                    e.preventDefault();

                    // Termek
                    var $product = $(this).closest('[data-product-id]');
                    var $options = $product.find('.options');

                    // Opciok torlese
                    $options.find('input[type="text"], input[type="number"]').val('');
                    $options.find('select').val(null).trigger('change');
                });

            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // (4) Kosar: Lista

        // Kosar: Lista
        list: function(){
            // Preloader
            Shop.cart.addClass('cart-loading');

            // API hivas
            $.ajax({
                type: 'POST',
                url: '/webaruhaz/ajax/kosar',
                dataType: 'json',
            }).done(function(res){
                // Megjelenites
                response = Shop.show(res);
            }).fail(function(){
                // Hiba tortent
                Page.showModal('message', 'Nem sikerült betölteni a kosarat.');
                // Preloader kikapcsolasa
                Shop.cart.removeClass('cart-loading');
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // (5) Kosar: Hozzaadas

        // Kosar: Hozzaadas
        add: function(params){
            // Preloader
            Shop.cart.addClass('cart-loading');

            // API hivas
            if(params.termek_id && params.darabszam > 0){
                $.ajax({
                    type: 'POST',
                    url: '/webaruhaz/ajax/kosarba',
                    data: params,
                    dataType: 'json'
                }).done(function(res){
                    // Megjelenites
                    Shop.list();
                    Page.showModal('message', 'A terméket hozzáadtuk a kosárhoz.', 'Kosár frissítése');
                }).fail(function(){
                    // Hiba tortent
                    Page.showModal('message', 'Hiba történt a kosárhoz adás során.');
                    // Preloader kikapcsolasa
                    Shop.cart.removeClass('cart-loading');
                });
            }
        },

//------------------------------------------------------------------------------------------------------------------------
    // (6) Kosar: Torles

        // Kosar: Torles
        remove: function(params){
            // Preloader
            Shop.cart.addClass('cart-loading');

            // API hivas
            if(params.termek_id){
                $.ajax({
                    type: 'POST',
                    url: '/webaruhaz/ajax/termekeltavolit',
                    data: params,
                    dataType: 'json'
                }).done(function(res){
                    // Megjelenites
                    Shop.list();
                    Page.showModal('message', 'A terméket töröltük a kosárból.', 'Kosár frissítése');
                }).fail(function(){
                    // Hiba tortent
                    Page.showModal('message', 'Hiba történt a kosárból törlés során.');
                    // Preloader kikapcsolasa
                    Shop.cart.removeClass('cart-loading');
                });
            }
        },

//------------------------------------------------------------------------------------------------------------------------
    // (7) Kosar: Modositas

        // Kosar: Modositas
        change: function(params){
            // Preloader
            Shop.cart.addClass('cart-loading');

            // API hivas
            if(params.termek_id && params.darabszam >= 0){
                $.ajax({
                    type: 'POST',
                    url: '/webaruhaz/ajax/mennyisegmodosit',
                    data: params,
                    dataType: 'json'
                }).done(function(res){
                    // Megjelenites
                    Shop.list();
                    //Page.showModal('message', 'A termék mennyiségét módosítottuk a kosárban.', 'Kosár frissítése');
                }).fail(function(){
                    // Hiba tortent
                    Page.showModal('message', 'Hiba történt a mennyiség módosítása során.');
                    // Preloader kikapcsolasa
                    Shop.cart.removeClass('cart-loading');
                });
            }
        },

//------------------------------------------------------------------------------------------------------------------------
    // (8) Kosar: Lista es popup frissitese

        // Kosar: Lista es popup frissitese
        show: function(products){
            // Popup tisztitas
            Shop.cartPopup.find('.cart-popup-empty').hide();
            Shop.cartPopup.find('.cart-popup-has-items').hide();
            Shop.cartPopup.find('.cart-popup-has-items strong').html('0');
            Shop.cartPopup.find('.cart-popup-items').empty().hide();
            Shop.cartPopup.find('.cart-popup-total .price').html('0 Ft');
            Shop.cartPopup.find('.cart-popup-total').hide();

            // Lista tisztitas
            Shop.cart.find('> tbody').empty();
            Shop.cart.find('.cart-sum-total-price').html('0 Ft');

            // Ha ures
            if(typeof products !== 'object' || $.isEmptyObject(products)){
                // Popup ures
                Shop.cartPopup.find('.cart-popup-empty').show();
                // Lista ures
                Shop.cart.find('> tbody').append('<tr><td colspan="6"><p class="cart-message">' + Shop.cart.find('> tbody').data('message-empty') + '</p></td></tr>');
            }
            // Ha vannak termekek
            else {
                // Kosar elemeinek hozzaadasa
                var total = 0, num = 0;
                $.each(products, function(i, item){
                    // Ar
                    var price = parseInt(item.termek['termek_ar_akcios']) ? parseInt(item.termek['termek_ar_akcios']) : parseInt(item.termek['termek_ar']);
                    var priceSale = parseInt(item.termek['termek_ar_akcios']);
                    var priceOld = parseInt(item.termek['termek_ar']);

                    // Meretek/tipusok szerinti darabszama
                    var amount = '', itemTotal = 0;
                    $.each(item.db, function(id, db){
                        amount += (amount ? ', ' : '') + db + ' db ' + (item.meret[id] && item.meret[id] != 'db' ? item.meret[id] : '');
                        itemTotal += parseInt(db);
                    });

                    // Termek hozzaadasa a popuphoz
                    var $item = $('<li data-product-id="' + item.termek['termek_id'] + '"><a href="/webaruhaz/termek/' + item.termek['termek_id'] + '/" class="thumbnail" style="background-image: url(\'' + item.termek['termek_kep'] + '\');" title="' + item.termek['termek_nev'] + '"></a></li>');
                    var $details = $('<div class="details" />');
                    $details.append($('<div class="category"><a href="/webaruhaz/kategoria/' + item.termek['termek_kategoria'] + '" title="Kategória neve">Kategória neve</a></div>'));
                    $details.append($('<h4><a href="/webaruhaz/termek/' + item.termek['termek_id'] + '/" class="title" title="' + item.termek['termek_nev'] + '">' + item.termek['termek_nev'] + '</a></h4>'));
                    $details.append($('<div class="excerpt">' + item.termek['termek_leiras'] + '</div>'));
                    $details.append($('<div class="info clearfix"><div class="quantity pull-left"><div><strong>Darabszám:</strong> ' + amount + '</div></div><div class="price pull-right">' + price + ' Ft</div></div>'));
                    $item.append($details);
                    Shop.cartPopup.find('.cart-popup-items').append($item);

                    // Termek hozzaadasa a listahoz
                    $item = $('<table class="cart-item" data-product-id="' + item.termek['termek_id'] + '"></table>');
                    var $columns = $('<tr />');
                    $columns.append($('<td class="cart-item-thumb"><a href="/webaruhaz/kategoria/' + item.termek['termek_kategoria'] + '" class="thumbnail" style="background-image: url(\'' + item.termek['termek_kep'] + '\');" title="' + item.termek['termek_nev'] + '"></a></td>'));
                    $columns.append($('<td class="cart-item-name"><h4><a href="/webaruhaz/kategoria/' + item.termek['termek_kategoria'] + '" class="title" title="' + item.termek['termek_nev'] + '">' + item.termek['termek_nev'] + '</a></h4></td>'));
                    $columns.append($('<td class="cart-item-unitprice"><input type="hidden" name="egysegar[' + item.termek['termek_id'] + ']" value="' + price + '" /><ul class="prices none"></ul></td>'));
                    $columns.append($('<td class="cart-item-amount"><ul class="amount none"></ul></td>'));
                    $columns.append($('<td class="cart-item-options"><a href="#" class="btn-remove" data-method="shop-remove" title="Törlés"><i class="fa fa-trash-alt"></i> <span class="visible-xs-inline">Törlés</span></a></td>'));
                    $columns.append($('<td class="cart-item-total"><span class="price"><span class="visible-xs-inline">Összesen: </span><strong><span>' + price * itemTotal + ' Ft</span></strong></span></td>'));
                    if(priceSale){
                        $columns.find('.cart-item-unitprice .prices').append('<li class="price"><span class="visible-xs-inline">Régi egységár: </span><del><span>' + priceOld + ' Ft</span></del></li>');
                        $columns.find('.cart-item-unitprice .prices').append('<li class="price"><span class="visible-xs-inline">Akciós egységár: </span><ins><span>' + priceSale + ' Ft</span></ins></li>');
                    } else {
                        $columns.find('.cart-item-unitprice .prices').append('<li class="price"><span class="visible-xs-inline">Egységár: </span><span>' + price + ' Ft</span></li>');
                    }
                    $.each(item.db, function(id, db){
                        amount += (amount ? ', ' : '') + db + ' db ' + (item.meret[id] && item.meret[id] != 'db' ? item.meret[id] : '');
                        $columns.find('.cart-item-amount .amount').append('<li data-size-id="' + id + '"><label for="product-amount-' + item.termek['termek_id'] + '-' + id + '">' + (item.meret[id] && item.meret[id] != 'db' ? item.meret[id] + ':' : '') + '</label><button type="button" class="btn" data-method="shop-dec"><i class="fa fa-minus"></i></button><input id="product-amount-' + item.termek['termek_id'] + '-' + id + '" name="mennyiseg[' + item.termek['termek_id'] + '][' + id + ']" type="text" class="form-control" required placeholder="db" value="' + parseInt(db) + '" data-method="shop-change" /><button type="button" class="btn" data-method="shop-inc"><i class="fa fa-plus"></i></button></li>');
                    });
                    $item.append($('<tbody />').append($columns));
                    Shop.cart.find('> tbody').append($('<tr />').append($('<td colspan="6" />').append($item)));

                    // Vegosszeg novelese
                    total += price * itemTotal;
                    num++;
                });

                // Popup frissitese
                Shop.cartPopup.find('.cart-popup-has-items strong').html(num);
                Shop.cartPopup.find('.cart-popup-has-items').show();
                Shop.cartPopup.find('.cart-popup-items').show();
                Shop.cartPopup.find('.cart-popup-total .price').html(total + ' Ft');
                Shop.cartPopup.find('.cart-popup-total').show();

                // Lista frissitese
                Shop.cart.find('.cart-sum-total-price').html(total + ' Ft');

                // Esemenyek
                Shop.eventBind(Shop.cart);
            }

            // Preloader kikapcsolasa
            Shop.cart.removeClass('cart-loading');
        },

//------------------------------------------------------------------------------------------------------------------------
    };
}(jQuery));
